/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import {
  Container,
  Link,
  Stack,
  Typography
} from "@mui/material"
import "./layout.css"
import Header from './header'


// Layout is applied automatically via gatsby-plugin-layout
const Layout = ({ children }) => {
  return (
    <div className="page-container"
      style={{
        position: "relative",
        minHeight: "100vh",
      }}>
      <Container maxWidth="xl" style={{ paddingBottom: "260px" }}>
        <Header />
        <main>
          {children}
        </main>
      </Container>
      <footer
        style={{
          fontSize: `var(--font-sm)`,
          background: "#f8f8f8",
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: "240px",
        }}
      >
        <Container maxWidth="xl">
          <Stack mt={2}>
            <Link href="/dessert-recipes/" underline="none" color="inherit" mt={4}>
              Dessert Recipes
            </Link>
            <Link href="/dessert-component-recipes/" underline="none" color="inherit" mt={2}>
              Dessert Component Recipes
            </Link>
            <Link href="/dessert-component-recipes-by-category/" underline="none" color="inherit" mt={2}>
              Dessert Component Recipes By Category
            </Link>
            <Typography variant="p" mt={4} mb={5}>
              Quenellegant © {new Date().getFullYear()}
            </Typography>
          </Stack>
        </Container>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
