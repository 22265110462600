import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  Typography,
  Box,
  Menu,
  MenuItem,
  Link,
  Grid,
  Stack,
  Hidden,
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Collapse
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import styled from '@emotion/styled'
import logo from '../images/logo_60.png'
import "./layout.css"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const Logo = styled.img`
  margin-bottom: 0px;
  margin-right: 16px;
`

export default function Header({ location, pageContext }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setOpen(false)
  }

  const handlePopoverOpen = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const categories = [
    { category: 'Plated Desserts', slug: 'plated-dessert-recipes', title: 'Plated Dessert Recipes' },
    { category: 'Moulded Bonbons', slug: 'bonbon-recipes', title: 'Bonbon Recipes' },
    { category: 'Gelato & Ice Cream', slug: 'gelato-and-ice-cream-recipes', title: 'Gelato and Icecream Recipes'},
    { category: 'Tarts', slug: 'tart-recipes', title: 'Tart Recipes'},
    { category: 'Cakes', slug: 'cake-recipes', title: 'Cake Recipes'},
    { category: 'Chocolate Bars', slug: 'chocolate-bar-recipes', title: 'Chocolate Bar Recipes'},
    { category: 'Truffles', slug: 'truffle-recipes', title: 'Truffle Recipes'},
    { category: 'Entremets', slug: 'entremet-recipes', title: 'Entremet Recipes'},
    { category: 'Macarons', slug: 'macaron-recipes', title: 'Macaron Recipes'},
    { category: 'Chocolate Tablets', slug: 'chocolate-tablet-recipes', title: 'Chocolate Tablet Recipes'},
    { category: 'Eclairs', slug: 'eclair-recipes', title: 'Eclair Recipes'},
    { category: 'Yule Logs', slug: 'yule-log-recipes', title: 'Yule Log Recipes'},
    { category: 'Mousses', slug: 'mousse-recipes', title: 'Mousse Recipes'},
    { category: 'Cookies', slug: 'cookie-recipes', title: 'Cookie Recipes'},
    { category: 'Cheesecakes', slug: 'cheesecake-recipes', title: 'Cheesecake Recipes'},
    { category: 'Drinks', slug: 'drink-recipes', title: 'Drink Recipes'},
    { category: 'Spreads', slug: 'spread-recipes', title: 'Spread Recipes'},
  ]


  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open)
  }

  const [dessertRecipesOpen, setDessertRecipesOpen] = React.useState(false)

  const handleClick = () => {
    setDessertRecipesOpen(!dessertRecipesOpen);
  };

  return (
    <Grid container alignItems="center" pt={6} pb={6}>
      <Grid item xs={9} sm={3}>
        <Link href="/" underline="none">
          <Box sx={{ display: "inline-flex", verticalAlign: "middle", alignItems: "center", width: "100%", justifyContent: "flex-start" }}>
            <Logo src={logo} alt="Logo" />
            <Typography variant="h6" sx={{ fontFamily: 'palatino' }} color="text.primary">
              {data.site.siteMetadata.title}
            </Typography>
          </Box>
        </Link>
      </Grid>
      <Hidden smDown>
        <Grid item xs={9}>
          <Stack direction="row" spacing={4} justifyContent="flex-end" alignItems="center">
            <Link href="/dessert-recipes/" underline="none" color="inherit" onMouseOver={handlePopoverOpen}>
              Dessert Recipes
            </Link>
            <Link href="/dessert-component-recipes/" underline="none" color="inherit">
              Dessert Component Recipes
            </Link>
          </Stack>

          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: -12,
              horizontal: "left",
            }}
            PaperProps={{
              onMouseEnter: handleOpen,
              onMouseLeave: handleClose
            }}
          >
            <MenuItem component={Link} href="/dessert-recipes/">All Dessert Recipes</MenuItem>
            { categories.map(({category, slug}) => {
              const path = `/${slug}/`

              return (
                <MenuItem key={category} component={Link} href={path}>{category}</MenuItem>
              )
            })}
          </Menu>
        </Grid>
        <Grid item xs={3}>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={3}>
          <Stack direction="row" justifyContent="end">
            <Button
              edge="start"
              color="inherit"
              aria-label="menu"
              variant="outlined"
              onClick={toggleDrawer(true)}
              size="small"
            >
              <MenuIcon sx={{ fontSize: "0.85rem", paddingRight: "4px" }}/>
              <Typography
                variant="button"
                color="primary.contrast"
                sx={{ fontSize: '0.65rem'}}
              >
                Menu
              </Typography>
            </Button>
          </Stack>

          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            <List
              sx={{ width: '280px', maxWidth: 360, bgcolor: 'background.paper', paddingLeft: '10px' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader onClick={toggleDrawer(false)} component="div" id="nested-list-subheader">
                  <Stack direction="row" alignItems="center" pt={1} pb={1}>
                    <CloseIcon sx={{ marginRight: "8px" }} />
                    Close Menu
                  </Stack>
                </ListSubheader>
              }
            >
              <ListItemButton onClick={handleClick}>
                <ListItemText primary={<Typography variant="h6">Dessert Recipes</Typography>} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={dessertRecipesOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  { categories.map(({category, slug}) => {
                    const path = `/${slug}/`

                    return (
                      <ListItemButton key={category} sx={{ pl: 4 }} component="a" href={path}>
                        <ListItemText primary={category} />
                      </ListItemButton>
                    )
                  })}
                </List>
              </Collapse>

              <ListItemButton component="a" href="/dessert-component-recipes/">
                <ListItemText primary={<Typography variant="h6">Dessert Component Recipes</Typography>} />
              </ListItemButton>
            </List>

          </Drawer>
        </Grid>
      </Hidden>
    </Grid>
  )
}
